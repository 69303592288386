<template>
    <div class="rooot_wrapper">
        <div class="wp_root_nologin" v-if="token == null">
            <div class="login_screen">
                <Login_tool />
            </div>
        </div>
        <div class="wp_root_login" v-else>
            <Header_tool :class="{header_none: this.$route.name == 'Info_tool'}" />
            <router-view :key ='$route.params'/>
        </div>
        <div id="loading_show" class="loading_show" style="position: fixed; left: 0; bottom: 0; top: 0%; right: 0%; text-align: center; background: rgba(0,0,0,.5); z-index: 999999999;">
            <div class="windows8"> 
                <div class="wBall" id="wBall_1"> 
                    <div class="wInnerBall"></div>
                </div>
                <div class="wBall" id="wBall_2">
                    <div class="wInnerBall"></div>
                </div>
                <div class="wBall" id="wBall_3">
                    <div class="wInnerBall"></div>
                </div> 
                <div class="wBall" id="wBall_4">
                    <div class="wInnerBall"></div> 
                </div>
                <div class="wBall" id="wBall_5"> 
                    <div class="wInnerBall"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import "./assets/css/style.css"
import Header_tool from './components/Header.vue'
import Login_tool from './components/Login.vue'
export default {
  name: 'App',
  data () {
        return {
            token: window.localStorage.getItem('token'),
        }
    },
    components: {
        Header_tool,
        Login_tool
    },
    created () {
        
    },
    methods: {
        
    }
}
</script>

<style>
</style>
