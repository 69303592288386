<template>
    <div class="root_header_page">
        <header id="header" :class="'header has-sticky sticky-shrink ' + classHeader">
            <div class="container ner_responesize">
                <div :class="'menu_mobile ' + active">
                    <div class="info_mobile">
                        <span class="user_namelogin">{{ us }}</span>
                        <img src="../assets/images/logout.png" @click="logout" alt="btn logout" class="btn_logout">
                    </div>
                    <p style="opacity:0;z-index:-1;">
                        <router-link v-if="$route.path != '/'" class="nav-item" to='/' title="">
                            <img src="../assets/images/logo.png" class="logo-mobile" alt="logo">
                        </router-link>
                        <a v-else class="nav-item" href='/' title="">
                            <img src="../assets/images/logo.png" class="logo-mobile" alt="logo">
                        </a>
                    </p>
                    <img src="../assets/images/back.png" alt="back" class="back_page" @click="goBack" v-if="this.$route.name != 'Home_tool'">
                </div>
            </div>
        </header>
        <!-- <CurvedBottomNavigation :options="options" v-model="selected" background-color='#07070A' foreground-color='' icon-color='#C01BA0'>
            <template #path="{ props }">
                <b>{{ props.path }}</b>
            </template>
        </CurvedBottomNavigation> -->
    </div>
</template>

<script>
import $ from 'jquery'
$( document ).ready(function() {
    
});
export default {
    name: 'Header_tool',
    data () {
        return {
            active: '',
            selected: 3,
            classHeader: '',
            showModal: false,
            showModal_dk: false,
            showModal_notiDk: false,
            token: window.localStorage.getItem('token'),
            timeToken: window.localStorage.getItem('time_token'),
            us: window.localStorage.getItem('userName'),
            fullname: '',
            picture: '',
            // options: [
            //     { id: 1, icon: "falive_icon", title: "", path: { name: "Home_tool" } },
            //     { id: 2, icon: "fasupport", title: "", path: {name: "ShootFish_tool"} },
            //     { id: 3, icon: "fahome_icon", title: "", path: { name: "Home_tool" } },
            //     { id: 5, icon: "favideo", title: "", path: { name: "Home_tool" } },
            //     { id: 4, icon: "falive18_icon", title: "", path: {name: "Info_tool"} },
            // ],
            others: ''
        }
    },
    components: {
        
    },
    created () {
        window.addEventListener('scroll', this.handleScroll)
        
        setInterval(() => {
            this.getCheckLogin()
        }, 60000);
        this.getInfo()
    },
    methods: {
        logout () {
            $('.loading_show').show().fadeIn(10)
            this.axios.post(this.api_logout,
                {

                },
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then(res => {
                $('#loading_show').css('display', 'none')
                localStorage.clear();
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
                setTimeout(() => {
                    window.location.href = '/'
                }, 2000);
            })
        },
        handleScroll () {
            if (window.scrollY > 100) {
                this.classHeader = 'active'
            }else{
                this.classHeader = ''
            }
        },
        getCheckLogin () {
            this.axios.get(this.api_checkLogin, {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }).then((response) => {
                if(response.data.data.user.is_login == 0){
                    this.logout()
                }else{
                    this.others = response
                }
            })
        },
        goBack() {
            this.$router.back();
        },
        getInfo () {
            this.axios.get(this.api_InfoUser, {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }).then((response) => {
                this.others = response
            }).catch((res) => {
                if(res.response.status == 401){
                    localStorage.clear();
                    setTimeout(() => {
                        window.location.href = '/'
                    }, 1000);
                }
                
            })
        },
    }
}
</script>
<style></style>
