<template>
    <div class="wrapper_home">
        <div class="container ner_responesize">
            <div class="wp_result_tool">
                <div class="frm_result_admin">
                    <ul>
                        <li><span>Hack</span></li>
                        <li><span>Tool</span></li>
                        <li><span>Code - Bot</span></li>
                    </ul>
                    <div class="result_frm">
                        <div class="input_result">
                            <label>Capital level: </label>
                            <input type="text" name="capitallevel" :value="capitallv" class="capital_level" disabled>
                        </div>
                        <div class="input_result">
                            <label>Tool Code: </label>
                            <input type="text" name="administrator" :value="tool_code" class="Administrator_rs" disabled>
                        </div>
                    </div>
                    <div class="robot_result">
                        <img src="../assets/images/robot.gif" alt="">
                        <div class="text_abs_robot">{{ displayedText }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Result_tool',
    data () {
        return {
            token: window.localStorage.getItem('token'),
            idGroup: window.localStorage.getItem('idGroup'),
            text: '',
            displayedText: '',
            index: 0,
            intervalId: null,

            tool_code: '',
            capitallv: '',
            robot: ''
        }
    },
    components: {
        
    },
    mounted () {
    },
    created () {
        this.getDataInput()
        this.startTyping()
        this.socketData()
        // setInterval(() => {
        //     this.getDataInput()
        //     this.index = 0
        //     this.text = this.displayedText = ''
        //     setTimeout(() => {
        //         this.startTyping()
        //     }, 300);
        // }, 60000);
    },
    computed: {
        
    },
    methods: {
        startTyping() {
            this.axios.get(this.api_getInput).then(res => {
                if(res.data.data.id_group == this.idGroup){
                    this.text = res.data.data.input
                }else{
                    this.text = 'XIN CHÀO'
                }
                
            })
            setTimeout(() => {
                this.intervalId = setInterval(() => {
                    if (this.index < this.text.length) {
                        this.displayedText += this.text[this.index];
                        this.index++;
                    } else {
                        clearInterval(this.intervalId);
                    }
                }, 250);
            }, 700);
        },
        getDataInput () {
            this.axios.get(this.api_getInput).then(res => {
                this.tool_code = res.data.data.tool_code
                this.capitallv = res.data.data.capital_level
                if(res.data.data.id_group == this.idGroup){
                    this.robot = res.data.data.input
                }else{
                    this.robot = 'XIN CHÀO'
                }
            })
        },
        socketData () {
            var this_root = this
            this.socket.on('send:input',function(result){
                this_root.index = 0
                this_root.text = this_root.displayedText = ''

                this_root.tool_code = result.data.tool_code
                this_root.capitallv = result.data.capital_level

                if(this_root.idGroup == result.data.id_group){
                    this_root.startTyping()
                }else{
                    this.text = 'XIN CHÀO'
                    this_root.startTyping()
                }
                // console.log('1111', result.data);
            })
        }
    }
}
</script>