<template>
    <div class="wrapper_home mg_top">
        <div class="container ner_responesize">
            <div class="wp_item_game">
                <div class="row">
                    <div class="col-md-6 col-sm-6 col-xs-6 item_game_propose" v-for="(item, index) in listgamecq9" :key="index">
                        <div class="img_pecent_game">
                            <a href="javascript:;">
                                <div class="img_nhacai">
                                    <img :src="item.img" alt="" class="img_game_pro">
                                </div>
                                <div class="conmemay">
                                    Loading <div class="classic-4"></div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btn_load_percen">
                <router-link to='/thong-so'>Click intrusion</router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Home_tool',
    data () {
        return {
            token: window.localStorage.getItem('token'),
            idUser: window.localStorage.getItem('numberus'),
            statusTime: window.localStorage.getItem('status_time'),
            type_cash: window.localStorage.getItem('type_cash'),
            listgamecq9: [], 
        }
    },
    components: {
        
    },
    mounted () {
        
    },
    created () {
        this.GetListGamecq9()
    },
    computed: {
        
    },
    methods: {
        shuffle(arra1) {
            var ctr = arra1.length, temp, index;
            while (ctr > 0) {
                index = Math.floor(Math.random() * ctr);
                ctr--;
                temp = arra1[ctr];
                arra1[ctr] = arra1[index];
                arra1[index] = temp;
            }
            return arra1;
        },
        GetListGamecq9 () {
            let arr = []
            for (let i = 0; i < 8; i++) {
                arr[i] = { img: require('@/assets/images/nhacai/'+ Number(i + 1) + '.png') }
            }
            this.listgamecq9 = this.shuffle(arr)
            this.listgamecq9.unshift({ img: require('@/assets/images/nhacai/tydo.png') })
        },
    }
}
</script>