<template>
    <div class="wrapper_home mg_top">
        <div class="container ner_responesize">
            <div class="wrapper_game_chicken">
                <div class="listgame_page">
                    <div class="content_tab_game">
                        <div class="wp_item_game">
                            <div class="row">
                                <div class="col-md-6 col-sm-6 col-xs-6 item_game_propose" v-for="(item, index) in listgamecq9" :key="index">
                                    <div class="img_pecent_game">
                                        <router-link to="/ket-qua">
                                            <div class="img_nhacai">
                                                <img :src="item.img" alt="" class="img_game_pro">
                                                <span>{{item.pecent}}%</span>
                                            </div>
                                            <img :src="item.imgpecent" alt="" class="img_pecent">
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Betting_tool',
    data () {
        return {
            token: window.localStorage.getItem('token'),
            listgamecq9: [], 
        }
    },
    components: {
        
    },
    mounted () {
        
    },
    created () {
        this.GetListGamecq9()
    },
    computed: {
        
    },
    methods: {
        shuffle(arra1) {
            var ctr = arra1.length, temp, index;
            while (ctr > 0) {
                index = Math.floor(Math.random() * ctr);
                ctr--;
                temp = arra1[ctr];
                arra1[ctr] = arra1[index];
                arra1[index] = temp;
            }
            return arra1;
        },
        GetListGamecq9 () {
            const arrayRandom = [30, 40, 50, 60, 30, 50, 60, 40]
            arrayRandom.sort(function() {
                return 0.5 - Math.random();
            });
            let arr = []
            for (let i = 0; i < arrayRandom.length; i++) {
                arr[i] = { img: require('@/assets/images/nhacai/'+ Number(i + 1) + '.png'), imgpecent: require('@/assets/images/'+ arrayRandom[i] + '.gif'), pecent: arrayRandom[i] }
            }

            const pc = ["91", "93", "95", "97"];
            const random = Math.floor(Math.random() * pc.length);
            this.listgamecq9 = this.shuffle(arr)
            this.listgamecq9.unshift({ img: require('@/assets/images/nhacai/tydo.png'), imgpecent: require('@/assets/images/' + pc[random] + '.gif'), pecent: pc[random] })
            
            // this.listgamecq9 = arr.slice().sort((a, b) => b.pecent - a.pecent)
        },
    }
}
</script>