import { createRouter, createWebHistory } from 'vue-router'

import Home_tool from '@/components/Home.vue'
import Betting_tool from '@/components/Betting.vue'
import Result_tool from '@/components/Result.vue'

const routes = [
    {
        path: '/',
        name: 'Home_tool',
        component: Home_tool
    },
    {
        path: '/thong-so',
        name: 'Betting_tool',
        component: Betting_tool
    },
    {
        path: '/Ket-qua',
        name: 'Result_tool',
        component: Result_tool
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
    linkActiveClass: "active"
})

export default router